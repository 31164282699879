import React, { Component } from "react";
import axios from "axios";
import { Suspense } from "react";
import { Link } from "react-router-dom";
import { BaseUrl } from "./api";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";



const cookies = new Cookies();
const ProductCard = React.lazy(() => import("../common/product-card"));

class StylesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stylesData: [],
            nameUz: "",
            nameRu: "",
            category: [],
            redirect: "",
            limit: 21,
            offset: 0,
            isLoading: true,
            notFound: false,
        };
        this._isMounted = false;

        if (cookies.get("Language")) {
            this.lang = cookies.get("Language");
        } else {
            this.lang = "uz";
        }
        if (this.lang === "uz") {
            this.name = "nameUz";
        } else {
            this.name = "nameRu";
        }
    }

    componentDidMount() {
        this._isMounted = true;
        window.scrollTo({ top: 0, left: 0, behavior: "auto" });
        this.setState({ _isMounted: true });  // Add this line
        this.fetchData();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this._isMounted = true;
            window.scrollTo({ top: 0, left: 0, behavior: "auto" });
            this.setState(
                {
                    isLoading: true,
                    category: [],
                },
                this.fetchData
            );
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    fetchData = async () => {
        this.getQuery();
        const response = await axios.get(
            BaseUrl + `collections/${this.category_id}/categories?` + this.url
        );
        let category = response.data.results;

        if (response.count === 0 || category.length === 0) {
            this._isMounted &&
                this.setState({
                    notFound: true,
                });
        } else {
            const nameUz = category[0].category_nameUz;
            const nameRu = category[0].category_nameRu;
            this._isMounted &&
                this.setState({
                    notFound: false,
                    nameUz,
                    nameRu,
                });
        }

        this._isMounted &&
            this.setState({
                category,
                isLoading: false,
            });
        try {
            const response = await axios.get(BaseUrl + `styles_cat?category_id=${this.category_id}`);
            this.setState({ stylesData: response.data });
        } catch (error) {
            console.error("Error fetching styles:", error);
        }
    };
    getQuery = async () => {
        this.queryString = require("query-string");
        this.parsed = this.queryString.parse(this.props.location.search, {
            arrayFormat: "comma",
        });
        this.category_id = this.parsed.category_id;
        if (this.parsed.offset) {
            this.offset = this.parsed.offset;
        } else {
            this.offset = this.state.offset;
        }
        if (this.parsed.limit) {
            this.url = this.queryString.stringify(this.parsed, {
                arrayFormat: "comma",
            });
        } else {
            this.url =
                this.queryString.stringify(this.parsed, { arrayFormat: "comma" }) +
                `&limit=${this.state.limit}`;
        }
        this.redirect = this.queryString.parse(this.url, { arrayFormat: "comma" });
        this.redirect["offset"] = 0;
        this.redirect = this.queryString.stringify(this.redirect, {
            arrayFormat: "comma",
        });
        this._isMounted &&
            this.setState({
                redirect: this.redirect,
            });
    };

    render() {
        const { stylesData } = this.state;
        const { t } = this.props;

        return (
            <Suspense fallback={<div>Loading...</div>}>
                <div className="container">
                    <div className="link-div">
                        <p
                            onClick={() => this.props.history.push("/")}
                            className="text-sm pointer"
                        >
                            {t("mainPage.text")}
                        </p>
                        <span>&nbsp; / &nbsp;</span>
                        <p
                            onClick={() =>
                                this.props.history.push(
                                    `/styles?category_id=${this.category_id}&nameUz=${this.state.nameUz}&nameRu=${this.state.nameRu}`
                                )
                            }
                            className="text-sm pointer"
                        >
                        </p>
 
                    </div>
                    <div className="collections-container">
                        {this.state.notFound && (
                            <h3>{t("notFound.text")}</h3>
                        )}
                        <div className={` pt-3 collections pb-3 `}>
                            {stylesData.map((style) => (

                                <Link
                                    key={style.id}
                                    to={
                                        `/collection?&` +
                                        this.state.redirect + `&styles=${style.id}`
                                    }
                                >
                                    <ProductCard
                                        key={style.id}
                                        image={style.image}
                                        name={style.nameUz}
                                        styles={'style'}
                                    />
                                </Link>

                            ))}
                        </div>
                    </div>
                </div>
            </Suspense>
        );
    }
}

export default withTranslation()(StylesPage);

// import logo from './logo.svg';
// import './App.css';

// import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import AppHeader from "./common/header";
import AppFooter from "./common/footer";
import Post from "./common/post";
import Blogs from "./common/blogs";
import MainPage from "./views/main";
import Category from "./views/category";
import Collection from "./views/collection";
import Product from "./views/product";
import SearchOne from "./views/search_1";
import SideBar from "./views/sidebar";

import Cart from "./views/cart";

import services from "./views/services";
import freeSize from "./views/freeSize";
import contacts from "./views/contacts";

import CheckOut from "./views/checkout";
// import OrdersInfo from "./views/ordersInfo";
import staticPages from "./views/static";

import Test from "./views/test";
import errorPage from "./common/errorPage";
import ordersInfo from "./views/ordersInfo";
import StylesPage from "./common/stylesPage";

class App extends React.Component {
  render() {
    return (
      <Router>
        <div className="parent">
          <div className="header container">
            <AppHeader
              onUpdate={() =>
                window.scrollTo({ top: 0, left: 0, behavior: "auto" })
              }
              id="header"
              parentData={this.cart_length}
            />
          </div>
          <div className="body">
            <Switch>
              <Route exact path="/" component={MainPage} />
              <Route path="/styles" component={StylesPage} />
              <Route path="/category" component={Category} />
              <Route path="/collection" component={Collection} />
              <Route path="/test" component={Test} />
              {/* <Route
                path="/product"
                component={() => (
                  <Product
                    {...this.state}
                    parentCallback={this.handleCallback}
                  />
                )}
              /> */}
              <Route path="/product" component={Product} />

              <Route path="/searcht" component={SearchOne} />
              <Route path="/sidebar" component={SideBar} />

              <Route path="/post/:id" component={Post} />
              <Route path="/blogs" component={Blogs} />
              <Route path="/cart" component={Cart} />
              <Route path="/checkout" component={CheckOut} />
              <Route path="/orderInfo/:id" component={ordersInfo} />

              <Route path="/services" component={services} />
              <Route path="/freesize" component={freeSize} />
              <Route path="/contacts" component={contacts} />
              <Route path="/static/:id" component={staticPages} />
              {/* <Route  path="/analytic" component={()=>(<AnalyticPage {...this.state}/>)} /> */}
              {/* <Route  path="/brands" component={()=>(<BrandPage {...this.state}/>)} /> */}
              <Route exact path="*" component={errorPage} />
            </Switch>
          </div>
          <div className="footer">
            <AppFooter />
          </div>
          {/* <AppFooter /> */}
        </div>
      </Router>
    );
  }
}
export default App;


